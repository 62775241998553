import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import OpenAccount from 'src/components/UI/Forms/OpenAccountRightForm'
import useDomReady from 'src/hooks/window/useDomReady'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import Hero from './sections/Hero/_index'
import Tabs from './sections/Tabs/_index'
import AindaNaoInveste from './sections/AindaNaoInveste/_index'
import Faq from './sections/Faq/_Faq'
import DisclaimerInterInvest from 'src/components/DisclaimerInterInvest'

import QrCodePortabilidade from './assets/qr-code-investimentos-portabilidade.png'
import QrCodeContaKids from './assets/qr-code-investimentos-contakids.png'
import pageContext from './pageContext.json'
import { Wrapper } from './styles'

const PortabilidadeDeInvestimentos = () => {
  const domReady = useDomReady()
  const [ openModal, setOpenModal ] = useState(false)
  const [ openAccountModal, setOpenAccontModal ] = useState(false)
  const [ sendDatalayerEvent ] = useDataLayer()

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_03',
    section_name: 'null',
    element_action: 'click button',
    element_name: 'Abra sua conta',
  })

  const dataLayerDisclaimer: IDataLayerParams = {
    section: 'dobra_05',
    section_name: 'Disclaimer',
    element_action: 'click button',
    element_name: '',
  }

  const portabilityModal = domReady && (
    <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
      <QrCodeModal
        isModal={openModal}
        setIsModal={setOpenModal}
        title='Escaneie o QR Code e dê início à sua portabilidade'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para o ambiente Apex dentro do app.'
        qrCode={QrCodePortabilidade}
      />
    </Modal>
  )

  const openAccont = domReady && (
    <Modal
      isModalOpen={openAccountModal}
      setIsModalOpen={setOpenAccontModal}
      locationToRender={document.body}
    >
      <OpenAccount
        closeModal={() => setOpenAccontModal(false)}
        formName='Formulario abertura de contas'
        dataLayer={dataLayer}
        qrCodeContaKids={QrCodeContaKids}
        qrCodeProduct={QrCodePortabilidade}
        qrBaixeApp={QrCodePortabilidade}
        deepLink='https://inter-co.onelink.me/Qyu7/8klmhflv'
      />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {portabilityModal}
        {openAccont}
        <Hero />
        <Tabs setOpenModal={setOpenModal} setDataLayer={setDataLayer} />
        <AindaNaoInveste openModal={setOpenAccontModal} setDataLayer={setDataLayer} />
        <Faq faq={pageContext.structuredData.faq} />
        <DisclaimerInterInvest
          sendDatalayerEvent={sendDatalayerEvent}
          dataLayer={dataLayerDisclaimer}
          section='dobra_05'
        />
      </Layout>
    </Wrapper>
  )
}

export default PortabilidadeDeInvestimentos
